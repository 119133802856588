import Vue from 'vue'
import VueRouter from 'vue-router'
import {isUserLoggedIn, userRole} from '@/auth/utils'
import clients from '@/router/clients'
import delivery from '@/router/delivery'
import banners from '@/router/banners'
import products from '@/router/products'
import order from '@/router/orders'
import shippingCompany from '@/router/shippingcompany'
import defaultcategory from '@/router/defaultcategory'
import marketplaces from '@/router/marketplace'
import affiliates from '@/router/affiliate'
import system from '@/router/system'
// :routes-imports://
Vue.use(VueRouter)

const routes = [
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('@/views/users/UsersList.vue'),
        meta: {
            roles: [5],
            pageTitle: 'Usuários',
            breadcrumb: [
                {
                    text: 'Usuários',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/dashboards/Analytics.vue'),
        meta: {
            roles: [1,2,5,6],
            pageTitle: 'Dashboards',
            breadcrumb: [
                {
                    text: 'Resumo',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/minha-conta',
        name: 'minha-conta',
        component: () => import('@/views/users/account-setting/AccountSetting.vue'),
        meta: {
            roles: [1,2,5,6],
            pageTitle: 'Minha Conta',
            breadcrumb: [
                {
                    text: 'Minha Conta',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/aprovar-cadastro-produtos',
        name: 'approve-product.description.index',
        component: () => import('@/views/products/approval-product-review/ReviewProductList.vue'),
        meta: {
            roles: [1,2,5,6],
            pageTitle: 'Produtos',
            breadcrumb: [
                {
                    text: 'Revisão de produtos',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/categories',
        name: 'product.categories.index',
        component: () => import('@/views/products/categories/CategoriesList.vue'),
        meta: {
            roles: [1,2,5,6],
            pageTitle: 'Categorias',
            breadcrumb: [
                {
                    text: 'Cadastro de Categoria',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/product-feed',
        name: 'product.feed.index',
        component: () => import('@/views/products/feed/ProductFeed.vue'),
        meta: {
            roles: [5],
            pageTitle: 'Feed de Produtos',
            breadcrumb: [
                {
                    text: 'Feed de Produtos',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/perdi-minha-senha',
        name: 'perdi-minha-senha',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/editor',
        name: 'editor.grape',
        component: () => import('@/views/MailEditor/GrapeJsditor.vue'),
    },
    {
        path: '/editor2',
        name: 'editor.vue',
        component: () => import('@/views/MailEditor/VueEmailEditor.vue'),
    },
    {
        path: '/recuperar-senha/:token',
        name: 'recuperar-senha',
        component: () => import('@/views/auth/ResetPassword.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        ...routes,
        ...clients,
        ...delivery,
        ...banners,
        ...products,
        ...order,
        ...shippingCompany,
        ...defaultcategory,
        ...marketplaces,
        ...affiliates,
        ...system
// :routes-unpack://
    ],
})

router.beforeEach(async (to, from, next) => {
    if (to.name === 'error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
        || to.name === 'recuperar-senha' || to.name === 'asdad') {
        await router.app.$store.dispatch('route/setLastRoute', from.fullPath)
        return next()
    }
    if (to.name && to.name.length) {
        if (!isUserLoggedIn() ) {
            router.push('login')
            return false
        }
        const roles = to.meta.roles || []

        if (!roles.find(item => item === userRole())) {
            router.push({name:'login'})
            return false
        }
    }
    const url = new URL(window.location.href)
    const lastRoute = url.pathname+url.search
    await  router.app.$store.dispatch('route/setLastRoute', lastRoute)
    return next()
    return true
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
