const product = [
  {
    path: '/products',
    name: 'products.index',
    component: () => import('@/views/products/ProductList'),
    meta: {
      pageTitle: 'Produtos',
      roles: [1, 2, 5,6],
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/new',
    name: 'products.new',
    component: () => import('@/views/products/product-form/ProductForm'),
    meta: {
      pageTitle: 'Produtos',
      roles: [1, 2, 5,6],
      breadcrumb: [
        {
          text: 'Lista',
          active: false,
          to: '/products',
        },
        {
          text: 'Novo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/:uuid/edit',
    name: 'products.edit',
    component: () => import('@/views/products/product-form/ProductForm'),
    meta: {
      roles: [1, 2, 5,6],
      pageTitle: 'Produtos',
      breadcrumb: [
        {
          text: 'Lista',
          active: false,
          to: {type:'history', default:'/products' },
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/catalog/products',
    name: 'products.catalog.index',
    component: () => import('@/views/products/catalog/ProductCatalogList'),
    meta: {
      roles: [1, 2, 5,6],
      pageTitle: 'Catálogo',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/catalogs/list',
    name: 'catalog.index',
    component: () => import('@/views/products/catalog/Cataloglist'),
    meta: {
      roles: [5],
      pageTitle: 'Catálogo de Produtos',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/price-table',
    name: 'price-table.list',
    component: () => import('@/views/tableprice/TablePriceList.vue'),
    meta: {
      roles: [1, 2, 5,6],
      pageTitle: 'Tabelas de Preços',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/price-table/new',
    name: 'price-table.new',
    component: () => import('@/views/tableprice/TablePriceForm.vue'),
    meta: {
      roles: [1, 5,6],
      pageTitle: 'Tabelas de Preços',
      breadcrumb: [
        {
          text: 'Lista',
          active: false,
          to: '/price-table',
        }, {
          text: 'novo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/price-table/:uuid/edit',
    name: 'price-table.edit',
    component: () => import('@/views/tableprice/TablePriceForm.vue'),

    meta: {
      roles: [1, 5,6],
      pageTitle: 'Tabelas de Preços',
      breadcrumb: [
        {
          text: 'Lista',
          active: false,
          to: '/price-table',
        }, {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/map/erp',
    name: 'products.categories.map',
    component: () => import('@/views/Categories/MapCategories.vue'),

    meta: {
      roles: [1, 5, 2,6],
      pageTitle: 'Mapear Categorias',
    },
  },
  {
    path: '/products/default-size-weight',
    name: 'products.default-size-weight',
    component: () => import('@/views/products/ProductDefaultSpecs.vue'),
    meta: {
      roles: [1, 2, 5, 6],
      pageTitle: 'Especificações Padrão de Produtos',
    }
  }
]

export default product
